import * as React from 'react';
import { Button } from '@/components/ui/button';
import { FaRegCirclePlay } from 'react-icons/fa6';
import { Dialog, DialogContent, DialogTrigger } from '@components/ui/dialog';

function TipsAndGuidelinesVideo() {
  return (
    <>
      <Dialog>
        <DialogTrigger asChild>
          <div className="aspect-video rounded-xl overflow-hidden mb-6 relative  h-auto w-full  max-h-[40vh]">
            <Button variant="default" className="!bg-white !text-black absolute left-4 bottom-4 z-10 rounded-full px-8">
              <FaRegCirclePlay />
              Watch Instructions
            </Button>
            <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/70" />
            <video muted loop autoPlay width={1280} height={720} className="object-cover h-full w-full">
              <source src="/videos/tips-and-guidelines-loop.mp4" type="video/mp4" />
              <source src="/videos/tips-and-guidelines-loop.mp4" type="video/ogg" />
              Your browser does not support the video tag.
            </video>
          </div>
        </DialogTrigger>
        <DialogContent className="p-0 aspect-video border-none max-w-screen-lg">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/_x9UIFFD__E?controls=0&autoplay=1&modestbranding=1&playsinline=0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default TipsAndGuidelinesVideo;
