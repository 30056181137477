import React from 'react';

import { Button } from '@components/ui/button';
import Link from 'next/link';
import { useSession } from '@hooks/index';
import { useAnimation } from 'framer-motion';

import NumberOne from '@components/Badge/NumberOne';
import SocialProof from '@components/Cards/SocialProof';
import OriginalVsGenerated from './OriginalVsGenerated';
import { useMediaQuery } from '@/hooks/use-media-query';

export default function Hero092724() {
  const { isLoggedIn } = useSession();
  const controls = useAnimation();
  const isDesktop = useMediaQuery('(min-width: 768px)');

  React.useEffect(() => {
    controls.start({
      x: '-50%',
      transition: {
        repeat: Infinity,
        duration: 60,
        ease: 'linear'
      }
    });
  }, [controls]);

  return (
    <div className="bg-background relative isolate overflow-hidden mb-6 lg:mb-16 ">
      <div className="mx-auto">
        <div className="absolute flex w-full top-0 left-0 right-0 h-full object-cover -z-40 bg-gradient-to-t from-background/90 via-background/90 to-background/70" />
        <div className="bg-red-500/0 grid grid-cols-1 px-4 md:px-10 lg:px-20 m-auto pt-6 lg:py-10 gap-6 pb-0">
          <div className="bg-red-500/0 relative flex flex-col items-center justify-center md:p-6 w-full">
            <div className="mb-4 md:mb-6 flex scale-75 md:scale-100">
              <NumberOne className="" />
            </div>
            <h1 className="text-4xl lg:text-6xl xl:text-7xl font-bold tracking-normal text-primary w-full md:w-fit text-left md:text-center">
              Generate photos <br />
              of yourself with AI
              <div className="mt-2 text-lg text-muted-foreground mb-4 md:mb-0">(Photos that look 100% real)</div>
            </h1>

            <div className="mt-0 lg:mt-3 m-auto mx-0 flex-col w-full flex md:flex-row items-center justify-center gap-x-6 gap-y-4 mb-10">
              {!isLoggedIn ? (
                <div className="w-full lg:w-fit relative">
                  <Button
                    className="w-full"
                    variant={'accent'}
                    size={isDesktop ? 'xl' : 'sm'}
                    asChild
                    data-element-location="hero"
                    data-cta-type="primary"
                    data-element-text="Get your portraits"
                    id="hero-primary-cta"
                  >
                    <Link
                      href={{
                        pathname: '/login',
                        query: { redirect: '/pricing', source: 'main_hero_cta' }
                      }}
                    >
                      Get your portraits
                    </Link>
                  </Button>
                </div>
              ) : (
                <>
                  <Button className="w-full md:w-fit" variant={'accent'} size={isDesktop ? 'xl' : 'sm'} asChild>
                    <Link href="/dashboard" className="">
                      Go to dashboard
                    </Link>
                  </Button>
                </>
              )}
            </div>
            <div className="mb-0 md:mb-10">
              <OriginalVsGenerated />
            </div>

            <div className="relative flex flex-col m-auto w-full">
              <SocialProof />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
