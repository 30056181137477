import { useExperiment } from '@statsig/react-bindings';
import SelfieToAiHeadshot from '@components/Marketing/Heroes/selfie-to-ai-headshot';
import Hero092724 from './Hero-09-27-24';
// import CarouselHero from './Heroes/carousel-hero';

export function HeroExperiment() {
  const experiment = useExperiment('hero_component_variation_a-b_test');
  const variant = experiment.get('variant');

  if (variant === 'a') {
    return <Hero092724 />;
  }
  // TODO: uncomment this when carousel hero is ready - need to learn how to add additional variant
  // if (variant === 'b') {
  //   return <CarouselHero />;
  // }
  return <SelfieToAiHeadshot />;
}
