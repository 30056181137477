import React from 'react';
import Image from 'next/image';
import { Button } from '@components/ui/button';
import Link from 'next/link';
import { ArrowRight, Check } from 'lucide-react';
import { Card } from '@components/ui/card';

import NumberOne from '@components/Badge/NumberOne';
import AiGeneratedBadge from '@components/Badge/ai-generated-badge';

export default function SelfieToAiHeadshot() {
  return (
    <div className="relative h-fit">
      <div className="mx-auto py-10 max-w-screen-2xl h-fit px-4 md:px-10 xl:px-20 ">
        <div className="flex flex-col lg:flex-row gap-8 md:gap-16 items-center justify-between">
          <div className="lg:w-1/2 flex flex-col gap-6">
            <div className="text-balance flex flex-col gap-2 w-full">
              <h2 className="text-4xl md:text-5xl font-bold text-primary items-center flex gap-2 text-center md:text-left w-fit mx-auto md:mx-0">
                Selfie <ArrowRight size={32} className="inline-block" />{' '}
                <span className="bg-gradient-to-r from-[#628BE7] via-[#638EEF] to-[#BB758E] bg-clip-text text-transparent">
                  AI Headshot
                </span>
              </h2>
              <p className="text-lg md:text-2xl text-muted-foreground text-center md:text-left">
                Get professional business headshots quickly and easily with AI—no studio, no equipment, just results.
              </p>
            </div>
            <ul className="hidden md:block space-y-2 text-muted-foreground pt-2 pb-4">
              <li className="flex items-center">
                <Check className="w-4 h-4 mr-2 text-green-500" />
                AI Photos that look 100% real
              </li>
              <li className="flex items-center">
                <Check className="w-4 h-4 mr-2 text-green-500" />
                Invoice suitable for business expenses
              </li>
              <li className="flex items-center">
                <Check className="w-4 h-4 mr-2 text-green-500" />
                Get high quality portraits in 15 minutes
              </li>
            </ul>
            <div className="space-y-2">
              <div className="flex space-x-4">
                <Button size="xl" variant="accent" className="w-full" asChild>
                  <Link href="/pricing">Generate your AI headshots</Link>
                </Button>
              </div>
              <div className=" bg-red-500/0 flex justify-start gap-6 items-center text-sm text-muted-foreground py-4">
                <div className="flex flex-col gap-2 w-1/2 justify-center items-center">
                  <div className="flex items-center">
                    <NumberOne />
                  </div>
                </div>
                <div className="flex flex-col gap-2 w-1/2 justify-center items-center">
                  <div className="flex -space-x-2 overflow-hidden">
                    {[
                      'https://storage.prompt-hunt.workers.dev/0fbbc50f-f824-49da-8f33-86c23cfa1cdc',
                      'https://storage.prompt-hunt.workers.dev/0e215b6b-3b00-4ba1-835a-309ec76cf538',
                      'https://storage.prompt-hunt.workers.dev/4e4ddd7d-e997-48fd-9182-7048db2262ae',
                      'https://storage.prompt-hunt.workers.dev/d8ae8f9a-9f34-47b7-beb1-a8d455e63c34'
                    ].map((src, index) => (
                      <img
                        key={index}
                        className="inline-block h-6 w-6 rounded-full ring-2 ring-muted"
                        src={src}
                        alt=""
                      />
                    ))}
                  </div>
                  <div className="text-sm md:text-base">86,000+ happy customers</div>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-1/2">
            <div className="grid grid-cols-2 gap-4 bg-red-500/0">
              <div className="space-y-4 justify-center items-center w-full flex flex-col relative">
                <svg
                  width="111"
                  height="233"
                  viewBox="0 0 111 233"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="absolute top-0 left-0 z-10 w-12"
                >
                  <path
                    d="M47.9696 7.53927C46.0171 10.0427 44.5593 12.8923 42.3593 15.2227C22.6235 35.3194 15.4797 60.3557 13.8346 87.0277C13.2649 96.2104 15.2337 106.248 18.7058 114.941C28.27 139.874 43.123 161.504 63.5399 178.919C69.4139 183.95 76.4062 187.92 84.0952 190.903C82.8491 188.742 81.5664 186.37 80.1095 184.246C75.9862 178.045 71.6522 171.88 67.9139 165.396C66.3471 162.641 65.7245 159.074 65.3493 155.679C65.1295 154.419 66.6972 152.199 67.9253 151.769C69.1535 151.338 71.6921 152.193 72.0861 153.205C80.6801 170.089 93.9305 183.78 105.815 198.359C109.966 203.474 109.746 207.189 104.493 210.701C91.4317 219.252 76.9407 224.592 61.4788 226.858C61.0572 226.932 60.2783 226.202 58.7938 225.164C61.2705 211.972 75.4212 213.395 85.1101 205.433C82.3241 204.405 80.0329 203.723 78.1267 202.758C40.8374 182.016 17.4351 149.983 4.00572 110.37C1.88958 104.467 1.13918 97.6776 1.09444 91.1973C1.07094 67.4118 7.17864 45.1516 20.4439 25.3189C24.7617 18.9424 31.0223 13.7407 36.8614 8.61267C39.098 6.4922 42.6817 5.86683 45.523 4.72218C46.3386 5.66121 47.1541 6.60024 47.9696 7.53927Z"
                    fill="white"
                  />
                </svg>

                <div className="flex items-center justify-between">
                  <Card className="flex flex-col space-y-2 p-2 rounded-lg mx-2 lg:mx-10">
                    <div className="text-xs text-center font-semibold">Uploaded selfies</div>
                    <div className="text-background grid grid-cols-3 gap-2 w-fit px-4 lg:px-8">
                      <Image
                        src="https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/ab3fa128-01c5-40f8-326b-616743d9d900/public"
                        alt="Profile 1"
                        width={50}
                        height={50}
                        className="rounded-md aspect-square w-full"
                      />
                      <Image
                        src="https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/7edd2eed-7ce2-493c-9f78-98062f915900/public"
                        alt="Profile 2"
                        width={50}
                        height={50}
                        className="rounded-md aspect-square w-full"
                      />
                      <Image
                        src="https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/32070298-6f2d-45a3-7e88-e00783e6f700/public"
                        alt="Profile 3"
                        width={50}
                        height={50}
                        className="rounded-md aspect-square w-full"
                      />
                    </div>
                  </Card>
                </div>
                <div className="aspect-[5/4] overflow-hidden w-full rounded-lg relative">
                  <AiGeneratedBadge className="absolute bottom-3 left-1/2 -translate-x-1/2" />
                  <Image
                    src="https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/3d58ac72-ba0f-4556-8630-ed8f49ebe600/public"
                    alt="Generated Image 1"
                    width={400}
                    height={320}
                    className="rounded-lg shadow-lg"
                  />
                </div>
                <div className="aspect-[5/4] overflow-hidden w-full rounded-lg relative">
                  <AiGeneratedBadge className="absolute bottom-3 left-1/2 -translate-x-1/2" />
                  <Image
                    src="https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/44484b07-eb8e-4ef9-304a-59e912766d00/public"
                    alt="Generated Image 2"
                    width={1000}
                    height={1000}
                    className="rounded-lg shadow-lg"
                  />
                </div>
              </div>
              <div className="space-y-4 relative">
                <div className="aspect-[5/4] overflow-hidden w-full rounded-lg relative">
                  <AiGeneratedBadge className="absolute bottom-3 left-1/2 -translate-x-1/2" />
                  <Image
                    src="https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/786eda6f-8396-4e7a-9377-376a35f16600/public"
                    alt="Generated Image 1"
                    width={400}
                    height={320}
                    className="rounded-lg shadow-lg"
                  />
                </div>
                <div className="aspect-[5/4] overflow-hidden w-full rounded-lg relative">
                  <AiGeneratedBadge className="absolute bottom-3 left-1/2 -translate-x-1/2" />

                  <Image
                    src="https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/a2e5a630-4e7f-4802-493f-d9bc32aaac00/public"
                    alt="Generated Image 2"
                    width={400}
                    height={320}
                    className="rounded-lg shadow-lg"
                  />
                </div>
                <svg
                  width="56"
                  height="173"
                  viewBox="0 0 56 173"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="absolute bottom-12 right-0 w-12"
                >
                  <g clip-path="url(#clip0_32_685)">
                    <path
                      d="M20.0304 173.759C27.4114 173.189 31.5364 168.393 34.7631 163.482C39.1606 156.591 42.868 149.155 45.677 141.602C57.7056 106.472 54.5814 71.9756 38.6609 38.7216C34.583 30.2814 29.2297 22.2855 23.8628 13.2225C33.6259 11.8936 43.0398 16.76 53.1936 15.9381C51.4347 11.9063 48.0496 11.0133 45.2634 10.1975C33.8193 6.89584 22.206 3.7245 10.5537 0.852573C4.94242 -0.479594 2.48248 0.877555 1.78152 6.26651C0.171462 17.4742 2.14118 28.0779 7.72956 37.7783C7.99005 38.1163 8.88831 38.2321 10.2163 38.5555C16.0586 31.0897 7.20333 23.099 10.8583 14.8946C22.5522 24.4688 29.1181 37.187 34.4083 50.3497C39.9201 64.1497 44.0261 78.225 44.6825 93.0734C45.3779 107.622 42.9488 121.616 39.283 135.755C35.9166 149.933 29.282 162.319 20.0304 173.759Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_32_685">
                      <rect width="56" height="173" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

                <div className="flex items-center justify-between">
                  <Card className="flex flex-col space-y-2 p-2 rounded-lg mx-2 lg:mx-10">
                    <div className="text-xs text-center font-semibold">Uploaded selfies</div>
                    <div className="text-background grid grid-cols-3 gap-2 w-fit px-4 lg:px-8">
                      <Image
                        src="https://www.picstudio.ai/images/marketing/klee1.webp"
                        alt="Profile 1"
                        width={50}
                        height={50}
                        className="rounded-md aspect-square w-full"
                      />
                      <Image
                        src="https://www.picstudio.ai/images/marketing/klee3.webp"
                        alt="Profile 2"
                        width={50}
                        height={50}
                        className="rounded-md aspect-square w-full"
                      />
                      <Image
                        src="https://www.picstudio.ai/images/marketing/klee4.webp"
                        alt="Profile 3"
                        width={50}
                        height={50}
                        className="rounded-md aspect-square w-full"
                      />
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
